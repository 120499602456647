import React from "react";
import classNames from "classnames";

import { Icon, IconMap } from "../Icon";
import { Circles, CirclesMap, CirclesProps } from "../Circles";
import { Markdown } from "../Markdown";

import styles from "./BubbleContent.module.scss";
import { InternalExternalLink } from "../InternalExternalLink";

interface BubbleContentProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  content?: string;
  icon?: keyof typeof IconMap;
  circle: keyof typeof CirclesMap;
  to?: string;
  circleClassName?: string;
  contentClassName?: string;
  circleProps?: Partial<CirclesProps>;
  squared?: boolean;
  variant?: number;
  guardLink?: boolean;
  renderContent?: boolean;
}

const BubbleContent: React.FC<BubbleContentProps> = ({
  title,
  content,
  icon,
  circle,
  className,
  to,
  circleClassName,
  contentClassName,
  circleProps = {},
  squared = true,
  variant = 0,
  children,
  guardLink,
  renderContent = true,
  ...props
}) => {
  const el = (children: React.ReactNode) =>
    to ? (
      <InternalExternalLink
        to={to}
        className={classNames(styles.content, contentClassName)}
        guard={guardLink}
      >
        {children}
      </InternalExternalLink>
    ) : (
      <span className={classNames(styles.content, contentClassName)}>
        {children}
      </span>
    );

  return (
    <div
      className={classNames(
        styles.bubble,
        styles[`variant${variant}`],
        className,
        {
          [styles.squared]: squared,
        }
      )}
      {...props}
    >
      {renderContent && (
        <>
          <Circles
            className={classNames(styles.bg, circleClassName)}
            stretch
            fill
            circleKey={circle}
            background
            {...circleProps}
          />
          {el(
            <>
              {title && <span className={styles.title}>{title}</span>}
              {content && <Markdown source={content} />}
              {children}
              {icon && <Icon name={icon} size="large" />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export { BubbleContent };
