type VoidFunction = () => void;

export const clickOutside = (
  el: HTMLElement,
  onClickOutside: VoidFunction,
  withExits = false,
  validator?: (el: HTMLElement, e: MouseEvent) => boolean
): VoidFunction => {
  const cb = (e: MouseEvent) => {
    if (validator) {
      if (validator(el, e)) {
        onClickOutside();
      }
    } else if (
      e.target &&
      e.target !== el &&
      !el.contains(e.target as HTMLElement)
    ) {
      onClickOutside();
    }
  };

  document.addEventListener("click", cb);
  const unregisterExits = withExits
    ? registerExits(onClickOutside)
    : () => false;

  return () => {
    unregisterExits();
    document.removeEventListener("click", cb);
  };
};

const KEYS = {
  ESCAPE: "Escape",
};

export const registerExits = (onEscape: VoidFunction): VoidFunction => {
  const cb = (e: KeyboardEvent) => {
    if ([KEYS.ESCAPE].includes(e.key)) {
      onEscape();
    }
  };

  document.addEventListener("keyup", cb);
  return () => document.removeEventListener("keyup", cb);
};

export type EventGeneric<T> = (evt: T) => void;

export function performantUpdate<T>(cb: EventGeneric<T>): EventGeneric<T> {
  let ticking = false;

  const update = (e: T) => {
    cb(e);
    ticking = false;
  };

  return (e: T) => {
    if (!ticking) {
      requestAnimationFrame(() => update(e));
      ticking = true;
    }
  };
}

export const registerDeviceSniff = (): Record<string, boolean> => {
  const browsers = {
    chrome: navigator.userAgent.indexOf("Chrome") > -1,
    explorer: navigator.userAgent.indexOf("MSIE") > -1,
    firefox: navigator.userAgent.indexOf("Firefox") > -1,
    safari: navigator.userAgent.indexOf("Safari") > -1,
    opera: navigator.userAgent.toLowerCase().indexOf("op") > -1,
  };

  const browserObject = Object.entries(browsers).find(entry => entry[1]);

  if (browserObject) {
    document.documentElement.classList.add(browserObject[0]);
  }

  return browsers;
};

export const registerPlatformSniff = (): void => {
  if (navigator.platform === "MacIntel") {
    document.documentElement.classList.add("mac");
  }

  if (navigator.platform === "Win32") {
    document.documentElement.classList.add("windows");
  }
};

export const registerBootlegVH = (): VoidFunction => {
  const setVh = () =>
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );

  const cb = performantUpdate(() => {
    setVh();
  });

  setVh();

  window.addEventListener("resize", cb, {
    passive: true,
  });

  window.addEventListener("orientationchange", cb, {
    passive: true,
  });

  return () => {
    window.removeEventListener("resize", cb);
    window.removeEventListener("orientationchange", cb);
  };
};
