import React from "react";
import ReactMarkdown, { ReactMarkdownProps } from "react-markdown";
import { CodeRenderer } from "./Code";

const Markdown: React.FC<ReactMarkdownProps> = ({ source }) => {
  return (
    <ReactMarkdown
      source={source}
      renderers={{
        inlineCode: CodeRenderer,
      }}
    />
  );
};

export { Markdown };
