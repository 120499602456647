import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";

import { ReactComponent as Circle001 } from "./assets/001.svg";
import { ReactComponent as Circle002 } from "./assets/002.svg";
import { ReactComponent as Circle003 } from "./assets/003.svg";
import { ReactComponent as Circle004 } from "./assets/004.svg";
import { ReactComponent as Circle005 } from "./assets/005.svg";
import { ReactComponent as Circle006 } from "./assets/006.svg";
import { ReactComponent as Circle007 } from "./assets/007.svg";
import { ReactComponent as Circle008 } from "./assets/008.svg";
import { ReactComponent as CircleSquared } from "./assets/squared.svg";

import styles from "./Circles.module.scss";

export const CirclesMap = {
  "001": Circle001,
  "002": Circle002,
  "003": Circle003,
  "004": Circle004,
  "005": Circle005,
  "006": Circle006,
  "007": Circle007,
  "008": Circle008,
  squared: CircleSquared,
};

export interface CirclesProps extends React.HTMLAttributes<HTMLDivElement> {
  circleKey: keyof typeof CirclesMap;
  fill?: boolean;
  stroke?: boolean;
  background?: boolean;
  appear?: boolean | number;
  stretch?: boolean;
  show?: boolean;
  animate?: boolean;
  offset?: string;
}

const Circles: React.FC<CirclesProps> = ({
  circleKey,
  className,
  fill,
  stroke,
  background,
  appear = false,
  stretch = false,
  show = true,
  animate = true,
  style = {},
  offset,
  ...props
}) => {
  const wrapperRef = useRef(null);
  const mountedRef = useRef(true);
  const [appeared, setAppeared] = useState(false);
  const [transition, setTransition] = useState(false);
  const [pathLength, setPathLength] = useState<number>(10000);
  const timerRef = useRef<number>(-1);
  const [measureRef, bounds] = useMeasure({
    polyfill: ResizeObserver,
  });

  const animateOffset = useMemo(() => {
    return offset || `${Math.random() * 2}s`;
  }, [offset]);

  const cls = classNames(
    styles.circle,
    className,
    {
      [styles.withFill]: fill,
    },
    {
      [styles.withStroke]: stroke,
    },
    {
      [styles.background]: background,
    },
    {
      [styles.shouldAppear]: !!appear,
    },
    {
      [styles.appeared]: appeared,
    },
    {
      [styles.transition]: transition,
    },
    {
      [styles.noPulse]: !animate,
    }
  );

  useEffect(() => {
    if (appear && show) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (typeof appear === "number") {
            timerRef.current = window.setTimeout(() => {
              if (mountedRef.current) {
                setAppeared(true);
              }
            }, appear);
          } else {
            requestAnimationFrame(() => {
              requestAnimationFrame(() => {
                if (mountedRef.current) {
                  setAppeared(true);
                }
              });
            });
          }
        });
      });
    } else if (!show) {
      if (mountedRef.current) {
        setAppeared(false);
      }
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [appear, show]);

  const onRef = useCallback((ref: any) => {
    if (ref) {
      wrapperRef.current = ref;
      measureRef(ref);

      setPathLength(ref.querySelector("svg:first-child path").getTotalLength());
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setTransition(true);
        });
      });
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (wrapperRef && wrapperRef.current !== null) {
      setPathLength(
        (wrapperRef.current as any)
          .querySelector("svg:first-child path")
          .getTotalLength()
      );
    }
  }, [bounds]);

  const El = CirclesMap[circleKey];

  const spanStyle = {
    ...style,
    "--total-path-length": pathLength,
    "--animate-offset": animateOffset,
  } as React.CSSProperties;

  const spread: React.SVGAttributes<SVGElement> = {};

  if (stretch) {
    spread.preserveAspectRatio = "none";
  }

  return (
    <span className={cls} {...props} ref={onRef} style={spanStyle}>
      <El {...spread} className={styles.stroke} />
      <El {...spread} className={styles.fill} />
    </span>
  );
};

export { Circles };
