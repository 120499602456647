import React, { useMemo, useState } from "react";
import classNames from "classnames";
import Modal from "react-modal";

import styles from "./InfoText.module.scss";
import { Markdown } from "../Markdown";
import { HightlightTitle } from "../HighlightTitle";
import { useAppContext } from "../../Context/AppContext";
import { Button } from "../Button";

// Modal.setAppElement(document.body);

interface InfoTextProps {
  text: string;
}

const InfoText: React.FC<InfoTextProps> = ({ text }) => {
  const { textDictionary } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);

  const item = useMemo(() => {
    return textDictionary[text.split(" ").join("-").toLowerCase()] || false;
  }, [text, textDictionary]);

  if (!item) {
    return <>{text}</>;
  }

  return (
    <>
      <Modal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        closeTimeoutMS={500}
      >
        <div className={styles.content}>
          <Button
            className={styles.close}
            icon="x"
            size="normal"
            onClick={() => setIsOpen(false)}
          />
          <HightlightTitle className={styles.title}>
            {item.title}
          </HightlightTitle>
          <Markdown source={item.description} />
        </div>
      </Modal>
      <button
        className={classNames(styles.btn)}
        onClick={() => setIsOpen(true)}
      >
        {text}
      </button>
    </>
  );
};

export { InfoText };
