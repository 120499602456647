import { MenuItemProps } from "./AppContextTypes";

export const MenuItem: Record<string, MenuItemProps> = {
  Home: {
    label: "Home",
    to: "/",
  },
  About: {
    to: "/about",
    label: "About Life Support",
  },
};
