import React from "react";
import classNames from "classnames";

import { ReactComponent as PlayIcon } from "./icons/play.svg";
import { ReactComponent as PauseIcon } from "./icons/pause.svg";
import { ReactComponent as BookmarkIcon } from "./icons/bookmark.svg";
import { ReactComponent as BookmarkFillIcon } from "./icons/bookmark-fill.svg";
import { ReactComponent as ConversationIcon } from "./icons/conversation.svg";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";
import { ReactComponent as XIcon } from "./icons/x.svg";
import { ReactComponent as HeadphonesIcon } from "./icons/headphones.svg";
import { ReactComponent as ChevronIcon } from "./icons/chevron.svg";
import { ReactComponent as DownloadIcon } from "./icons/download.svg";
import { ReactComponent as ExternalIcon } from "./icons/external.svg";
import { ReactComponent as InfoIcon } from "./icons/info.svg";
import { ReactComponent as SwipeArrowIcon } from "./icons/swipe-arrow.svg";
import { ReactComponent as QuestionIcon } from "./icons/question.svg";
import { ReactComponent as ReplayIcon } from "./icons/replay.svg";

import styles from "./Icon.module.scss";

export const IconMap = {
  play: PlayIcon,
  pause: PauseIcon,
  bookmark: BookmarkIcon,
  "bookmark-fill": BookmarkFillIcon,
  conversation: ConversationIcon,
  menu: MenuIcon,
  x: XIcon,
  headphones: HeadphonesIcon,
  chevron: ChevronIcon,
  download: DownloadIcon,
  external: ExternalIcon,
  info: InfoIcon,
  "swipe-arrow": SwipeArrowIcon,
  question: QuestionIcon,
  replay: ReplayIcon,
};

interface IconProps {
  className?: string;
  name: keyof typeof IconMap;
  size?: "small" | "normal" | "large" | "xlarge";
  fill?: boolean;
}

const Icon: React.FC<IconProps> = ({ className, name, size, fill }) => {
  const cls = classNames(
    styles.icon,
    className,
    {
      [styles[size || ""]]: !!size,
    },
    {
      [styles.fill]: fill,
    }
  );

  const IconEl = IconMap[name];

  return (
    <span className={cls}>
      <IconEl />
    </span>
  );
};

export { Icon };
