// window.MyNamespace = window.MyNamespace || {};

export const GTAG_ID = "G-3XFRPWWR2J";

// export const load = (): Promise<void> => {
//   window.dataLayer = window.dataLayer || [];
//   window.gtag = function (...args: any[]) {
//     window.dataLayer.push(args);
//   };
//   window.gtag("js", new Date());

//   window.gtag("config", "G-3XFRPWWR2J");

//   return new Promise((resolve, reject) => {
//     const script = document.createElement("script");
//     script.onload = () => {
//       resolve();
//     };
//     script.onerror = () => {
//       reject();
//     };
//     script.async = true;
//     script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
//     // script.id = "gtag";
//     document.head.appendChild(script);
//   });
// };

export const loadScripts = () => {
  return new Promise(resolve => {
    const script = document.createElement("script");
    script.onload = () => {
      console.log("GA Loaded");
      resolve();
    };
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;

    document.head.appendChild(script);

    const script2 = document.createElement("script");
    script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', '${GTAG_ID}');`;

    document.head.appendChild(script2);
  });
};

export const pageview = url => {
  if (!window.gtag) {
    return;
  }

  window.gtag("config", GTAG_ID, {
    page_location: url,
  });
};

export const event = ({
  action,
  category = undefined,
  label = undefined,
  value = undefined,
}) => {
  if (!window.gtag) {
    return;
  }

  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
