import React, { useEffect, useState } from "react";

import { ReactComponent as LogoSvg } from "../../../images/logo.svg";
import styles from "./BrowserBlock.module.scss";

export const shouldBlockBrowser = (): boolean => {
  const isIE =
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > -1;

  return isIE;
};

const BrowserBlock: React.FC = () => {
  return (
    <div className={styles.block}>
      <div className={styles.content}>
        <LogoSvg />
        <p>
          Unfortunately this experience is not supported by your Web Browser.
        </p>
        <p>Please view this site via a browser such as:</p>
        <ul>
          <li>
            <a
              href="https://www.google.com/intl/en_uk/chrome/"
              rel="noreferrer"
              target="_blank"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://www.mozilla.org/en-GB/firefox/new/"
              rel="noreferrer"
              target="_blank"
            >
              Firefox
            </a>
          </li>
          <li>
            <a
              href="https://www.microsoft.com/en-us/edge"
              rel="noreferrer"
              target="_blank"
            >
              Microsoft Edge
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { BrowserBlock };
