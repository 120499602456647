import { boolean } from "@storybook/addon-knobs";
import React from "react";

import classNames from "classnames";
import { ReactComponent as BackgroundSvg } from "./assets/bg.svg";

import styles from "./HighlightTitle.module.scss";

interface HighlightTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  withBackground?: boolean;
  tagName?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
}

const HightlightTitle: React.FC<HighlightTitleProps> = ({
  withBackground,
  className,
  tagName: El = "p",
  children,
  ...props
}) => {
  return (
    <El className={classNames(styles.title, className)} {...props}>
      <BackgroundSvg className={styles.bg} />
      <span className={styles.inner}>{children}</span>
    </El>
  );
};

export { HightlightTitle };
