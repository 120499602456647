import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import styles from "./Header.module.scss";
import { Button } from "../../common/Button";
import { Menu } from "../Menu";
// import { MenuItemProps } from "../Menu/common";
import { Overlay } from "../../common/Overlay";
import { useAppContext } from "../../Context/AppContext";
import { ReactComponent as LogoLineSvg } from "../../../images/logo-small.svg";

// interface HeaderProps {}

const Header: React.FC = () => {
  const { menuItems, intro, advanceIntro } = useAppContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const cls = classNames(styles.header, { [styles.open]: menuOpen });

  useEffect(() => {
    setMenuOpen(false);

    if (location.pathname !== "/") {
      advanceIntro();
    }
  }, [advanceIntro, location]);

  return (
    <>
      <header className={cls}>
        <Link
          to="/"
          className={classNames(styles.logo, { [styles.show]: !intro })}
        >
          <LogoLineSvg />
        </Link>

        <Button
          icon="menu"
          size="normal"
          className={classNames(styles.btn, { [styles.show]: !intro })}
          onClick={() => setMenuOpen(true)}
          aria-label="Menu button"
        />
      </header>
      <Overlay
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
        usePortal
        contentClassName={styles.content}
        narrow
      >
        <Menu items={menuItems} />

        <p className={styles.copyright}>Copyright of Liminal Space 2021</p>
      </Overlay>
    </>
  );
};

export { Header };
