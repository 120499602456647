import React, { useMemo } from "react";
import { Link } from "gatsby";
import { AudioItem } from "../../../types/data";
import { Button } from "../../common/Button";
import { useAppContext } from "../../Context/AppContext";
import * as Analytics from "../../../utils/analytics.js";

import styles from "./MenuAudioItem.module.scss";

interface MenuAudioItemProps {
  audioId: string;
  audioItems: AudioItem[];
}

const MenuAudioItem: React.FC<MenuAudioItemProps> = ({
  audioId,
  audioItems,
}) => {
  const { toggleAudioFile } = useAppContext();

  const item = useMemo<AudioItem | undefined>(() => {
    return audioItems.find(a => a.targetId === audioId);
  }, [audioId, audioItems]);

  if (!item) {
    return null;
  }

  return (
    // <Link to={`/story/${item.story}#${item.targetId}`} className={styles.item}>
    <div className={styles.item}>
      <Link
        to={`/story/${item.story}#${item.targetId}`}
        className={styles.title}
      >
        {item.attribution}
      </Link>
      <Link
        to={`/story/${item.story}#${item.targetId}`}
        className={styles.info}
      >
        {item.snippet}
      </Link>
      <div className={styles.actions}>
        <Button
          className={styles.bookmark}
          buttonType="accent"
          icon="bookmark-fill"
          size="xlarge"
          onClick={() => {
            Analytics.event({
              action: "click",
              category: "Audio Remove Bookmark",
            });
            toggleAudioFile(audioId);
          }}
        />
      </div>
    </div>
    // </Link>
  );
};

export { MenuAudioItem };
