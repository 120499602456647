import React, { useMemo } from "react";
import { Link } from "gatsby";
import { useAppContext } from "../../Context/AppContext";

interface InternalExternalLinkProps extends React.HTMLAttributes<HTMLElement> {
  to: string;
  children: React.ReactNode;
  guard?: boolean;
}

const InternalExternalLink: React.FC<InternalExternalLinkProps> = ({
  to,
  children,
  guard,
  ...props
}) => {
  const { setExternal } = useAppContext();

  const isExternal = useMemo(() => {
    return to.startsWith("http");
  }, [to]);

  if (isExternal) {
    if (guard) {
      return (
        <button onClick={() => setExternal({ link: to })} {...props}>
          {children}
        </button>
      );
    } else {
      return (
        <a href={to} target="_blank" rel="noreferrer noopener" {...props}>
          {children}
        </a>
      );
    }
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export { InternalExternalLink };
