/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";

import "normalize.css";
import "../styles/global.scss";

import { useLocation } from "@reach/router";

import { Header } from "./Layout/Header";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useAppContext } from "./Context/AppContext";
import { ExternalOverlay } from "./Layout/ExternalOverlay";
import { BrowserBlock } from "./Layout/BrowserBlock";
import { registerBootlegVH, registerDeviceSniff } from "../utils/dom";
import { Consent } from "./Layout/Consent";

interface LayoutProps {
  children: React.ReactNode;
  // location: Locat
}

const variants: Variants = {
  initial: {
    opacity: 1,
  },
  enter: {
    transition: {
      duration: 0.1,
    },
  },
  exit: {
    transition: {
      duration: 0.1,
    },
  },
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const { setPageAnimating } = useAppContext();

  if (typeof window !== "undefined" && (window as any).unsupportedBrowser) {
    return <BrowserBlock />;
  }

  return (
    <>
      <Header />
      <ExternalOverlay />

      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.main
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          key={location.pathname}
          onAnimationStart={() => setPageAnimating(true)}
          onAnimationComplete={() => setPageAnimating(false)}
        >
          {children}
        </motion.main>
      </AnimatePresence>

      <Consent />
    </>
  );
};

export { Layout };
