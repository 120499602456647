/* eslint-disable @typescript-eslint/no-var-requires */

require("intersection-observer");
require("proxy-polyfill");
const React = require("react");
const smoothscroll = require("smoothscroll-polyfill");
const { Cookies } = require("react-cookie-consent");
const { Layout } = require("./src/components/layout.tsx");
const { AppContainer } = require("./src/components/Context/AppContext");
const Analytics = require("./src/utils/analytics.js");
const { COOKIE_NAME } = require("./src/utils/constants");
const { shouldBlockBrowser } = require("./src/components/Layout/BrowserBlock");
const {
  registerBootlegVH,
  registerDeviceSniff,
  registerPlatformSniff,
} = require("./src/utils/dom");

exports.wrapPageElement = ({ element, props }) => {
  return (
    <AppContainer location={props.location}>
      <Layout {...props}>{element}</Layout>
    </AppContainer>
  );
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  if (location.pathname.startsWith("/story")) {
    return false;
  }

  window.scrollTo(...(currentPosition || [0, 0]));
};

exports.onClientEntry = () => {
  window.unsupportedBrowser = shouldBlockBrowser();
  // kick off the polyfill!
  smoothscroll.polyfill();

  const consent = Cookies.get(COOKIE_NAME);

  if (consent && parseInt(consent)) {
    Analytics.loadScripts();
  }

  registerBootlegVH();
  registerDeviceSniff();
  registerPlatformSniff();
};

exports.onRouteUpdate = ({ location }) => {
  let locationStr = "";

  if (location) {
    locationStr = "" + location.pathname + location.search + location.hash;
  }

  Analytics.pageview(locationStr);
};
