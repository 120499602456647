import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

import { COOKIE_NAME } from "../../../utils/constants";
import * as Analytics from "../../../utils/analytics.js";

import styles from "./Consent.module.scss";

const Consent: React.FC = () => {
  return (
    <CookieConsent
      onAccept={() => {
        Analytics.loadScripts();
      }}
      buttonText="Okay, no problem"
      cookieName={COOKIE_NAME}
      cookieValue={1}
      buttonClasses={styles.btn}
      declineButtonClasses={styles.decline}
      containerClasses={styles.container}
      contentClasses={styles.content}
      declineButtonText="No thanks"
      disableStyles
      enableDeclineButton
    >
      This site uses cookies. More information can be found in the{" "}
      <Link to="/privacy">Privacy&nbsp;Policy</Link>
    </CookieConsent>
  );
};

export { Consent };
