import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { Button } from "../Button";
import styles from "./Overlay.module.scss";
import { registerExits } from "../../../utils/dom";
import { AnimatePresence, motion } from "framer-motion";

interface OverlayProps {
  open?: boolean;
  onClose: () => void;
  usePortal?: boolean;
  children: React.ReactNode;
  overlayPortalId?: string;
  className?: string;
  contentClassName?: string;
  backdropClassName?: string;
  narrow?: boolean;
}

const Overlay: React.FC<OverlayProps> = ({
  open,
  usePortal,
  onClose,
  children,
  overlayPortalId = "overlay-portal",
  className,
  contentClassName,
  backdropClassName,
  narrow,
}) => {
  const portalRef = useRef<HTMLElement | null>(null);
  const [divSet, setDivSet] = useState<number>(0);

  useEffect(() => {
    if (!usePortal) {
      return;
    }

    const el = document.querySelector(`#${overlayPortalId}`);
    if (!el) {
      const div = document.createElement("DIV");
      div.id = overlayPortalId;
      document.body.appendChild(div);
      portalRef.current = div;
      setDivSet(state => state + 1);
    } else {
      portalRef.current = el as HTMLElement;
    }
  }, [usePortal, overlayPortalId]);

  useEffect(() => {
    if (!open) {
      return;
    }

    setDivSet(state => state + 1);
    return registerExits(onClose);
  }, [open, onClose]);

  const el = (
    <AnimatePresence>
      {open && (
        <>
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={classNames(
              styles.overlay,
              { [styles.narrow]: narrow },
              className
            )}
          >
            <Button
              icon="x"
              size="normal"
              className={styles.btn}
              onClick={onClose}
            />

            <div className={classNames(styles.content, contentClassName)}>
              {children}
            </div>
          </motion.section>
          <motion.div
            role="button"
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={classNames(styles.backdrop, backdropClassName)}
          />
        </>
      )}
    </AnimatePresence>
  );

  if (usePortal && portalRef.current) {
    return ReactDOM.createPortal(el, portalRef.current);
  }

  return el;
};

export { Overlay };
