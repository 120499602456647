import React from "react";
import classNames from "classnames";

import { IconMap, Icon } from "../Icon";

import styles from "./Button.module.scss";

export interface ButtonContent {
  icon?: keyof typeof IconMap;
  size?: "small" | "normal" | "large" | "xlarge";
  buttonType?: "none" | "accent" | "accentFill";
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonContent {
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  icon,
  children,
  className,
  size = "normal",
  buttonType = "none",
  ...props
}) => {
  const cls = classNames(
    styles.button,
    {
      [styles.noChildren]: !children,
    },
    [styles[size]],
    [styles[buttonType]],
    className
  );

  return (
    <button className={cls} {...props}>
      {icon && <Icon name={icon} className={styles.icon} />}
      {children}
    </button>
  );
};

export { Button };
