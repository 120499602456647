import { useState, useEffect } from "react";
import { performantUpdate } from "./dom";

export const useActive = (): boolean => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setActive(true);
      });
    });
  }, []);

  return active;
};

export interface Dimensions {
  width: number;
  height: number;
}

export const useDocumentDimensions = (target?: string): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const getDimensions = (): Dimensions => {
      if (target) {
        const el = document.querySelector(target);
        if (!el) {
          return { width: 0, height: 0 };
        }

        return {
          width: el.clientWidth,
          height: el.clientHeight,
        };
      } else {
        return {
          width: document.documentElement.offsetWidth,
          height: document.documentElement.offsetHeight,
        };
      }
    };
    setDimensions(getDimensions());

    const cb = performantUpdate(() => {
      setDimensions(getDimensions());
    });

    window.addEventListener("resize", cb, {
      passive: true,
    });

    return () => window.removeEventListener("resize", cb);
  }, [target]);

  return dimensions;
};

export const useWindowDimensions = (): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const getDimensions = (): Dimensions => {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    };
    setDimensions(getDimensions());

    const cb = performantUpdate(() => {
      setDimensions(getDimensions());
    });

    window.addEventListener("resize", cb, {
      passive: true,
    });

    return () => window.removeEventListener("resize", cb);
  }, []);

  return dimensions;
};
