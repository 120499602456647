import React, { useState } from "react";
import Link from "gatsby-link";
import classNames from "classnames";
import { useMatch } from "@reach/router";

import { MenuItemProps } from "./common";
import { Icon } from "../../common/Icon";
import { ReactComponent as BottomSvg } from "./assets/bottom.svg";
import { ReactComponent as TopSvg } from "./assets/top.svg";
import { ReactComponent as Top2Svg } from "./assets/top2.svg";
import { Menu } from ".";
import styles from "./Menu.module.scss";

interface MenuItemWrapperProps
  extends Pick<MenuItemProps, "to" | "subItems" | "renderChild"> {
  subOpen: boolean;
  onSubOpen?: (subOpen: boolean) => void;
}

interface MenuItemParentProps
  extends MenuItemWrapperProps,
    Pick<MenuItemProps, "icon" | "label" | "className" | "odd"> {
  active: boolean;
}

const MenuItemWrapper: React.FC<MenuItemWrapperProps> = ({
  to,
  subItems,
  subOpen,
  children,
  onSubOpen,
  renderChild,
}) => {
  if (renderChild) {
    return <>{renderChild}</>;
  }

  if (to) {
    return (
      <Link to={to} className={styles.btn}>
        {children}
      </Link>
    );
  }

  if (subItems && subItems.length > 0 && onSubOpen) {
    return (
      <button
        className={classNames(styles.btn, styles.trigger, {
          [styles.open]: subOpen,
        })}
        onClick={() => onSubOpen(!subOpen)}
      >
        {children}
      </button>
    );
  }

  return (
    <span className={classNames(styles.btn, styles.disabled)}>{children}</span>
  );
};

const MenuItemParent: React.FC<MenuItemParentProps> = ({
  to,
  icon,
  onSubOpen,
  subOpen,
  label,
  active,
  renderChild,
  subItems,
  odd,
}) => {
  return (
    <MenuItemWrapper
      to={to}
      subItems={subItems}
      subOpen={subOpen}
      onSubOpen={onSubOpen}
      renderChild={renderChild}
    >
      <div className={styles.irregular}>
        {odd ? (
          <TopSvg className={styles.top} preserveAspectRatio="none" />
        ) : (
          <Top2Svg className={styles.top} preserveAspectRatio="none" />
        )}
        <BottomSvg className={styles.bottom} />
      </div>
      <span className={styles.leftSlot}>
        {icon && <Icon name={icon} />}
        {active && <span className={styles.dot} />}
      </span>
      <span className={styles.label}>{label}</span>
      <span className={styles.iconWrapper}>
        {subItems && (
          <Icon className={styles.icon} size="large" name="chevron" />
        )}
      </span>
    </MenuItemWrapper>
  );
};

const MenuItem: React.FC<MenuItemProps> = ({
  className,
  subItems,
  to,
  label,
  sub,
  subOpen,
  odd,
  onRequestOpen,
  ...rest
}) => {
  const active = useMatch(to || "/never");

  return (
    <li
      className={classNames(
        styles.item,
        { [styles.sub]: sub },
        { [styles.odd]: odd },
        className
      )}
    >
      <MenuItemParent
        active={!!active}
        subOpen={!!subOpen}
        onSubOpen={
          (onRequestOpen && (open => onRequestOpen(label || "", open))) ||
          undefined
        }
        to={to}
        label={label}
        subItems={subItems}
        odd={odd}
        {...rest}
      />
      {subOpen && subItems && <Menu sub items={subItems} />}
    </li>
  );
};

export { MenuItem };
