import React, { useCallback, useState } from "react";
import classNames from "classnames";

import styles from "./Menu.module.scss";
import { MenuItemProps } from "./common";
import { MenuItem } from "./MenuItem";

interface MenuProps extends React.HTMLAttributes<HTMLUListElement> {
  items: MenuItemProps[];
  sub?: boolean;
}

const Menu: React.FC<MenuProps> = ({ items, className, sub, ...props }) => {
  const [subOpen, setSubOpen] = useState<string | boolean>(false);

  const onRequestOpen = useCallback((lbl, open) => {
    setSubOpen(open ? lbl : open);
  }, []);

  return (
    <ul className={classNames(styles.list, className)} {...props}>
      {items.map((item, idx) => (
        <MenuItem
          key={idx}
          odd={idx % 2 === 1}
          {...item}
          sub={sub}
          subOpen={subOpen === item.label}
          onRequestOpen={onRequestOpen}
        />
      ))}
    </ul>
  );
};

export { Menu };
