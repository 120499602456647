import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import { BubbleContent } from "../../common/BubbleContent";
import { Markdown } from "../../common/Markdown";
import { Overlay } from "../../common/Overlay";
import { useAppContext } from "../../Context/AppContext";

import styles from "./ExternalOverlay.module.scss";

interface ExternalLinkInfo {
  link: string;
  title?: string;
  description?: string;
  information?: string;
}

interface StaticQueryProps {
  links: {
    edges: Array<{ node: ExternalLinkInfo }>;
  };
}

const ExternalOverlay: React.FC = () => {
  const { setExternal, external } = useAppContext();

  const data = useStaticQuery<StaticQueryProps>(graphql`
    query LinkQuery {
      links: allGoogleSheetExternalLinkDictionaryRow {
        edges {
          node {
            link
            title
            description
            information
          }
        }
      }
    }
  `);

  const item = useMemo(() => {
    if (!external) {
      return false;
    }

    return data.links.edges.find(
      ({ node }) => node.link.trim() === external.link.trim()
    );
  }, [data, external]);

  if (!item) {
    return null;
  }

  return (
    <Overlay
      open={!!external}
      onClose={() => setExternal(false)}
      className={styles.outer}
      contentClassName={styles.content}
    >
      <div className={styles.info}>
        <h2 className={styles.title}>
          {item && item.node.title ? item.node.title : "External link"}
        </h2>
        {item && item.node.description ? (
          <Markdown source={item.node.description} />
        ) : (
          <p>You are leaving this site by clicking this link.</p>
        )}
      </div>
      {item && item.node.information && (
        <div className={styles.infoStep}>
          <Markdown source={item.node.information} />
        </div>
      )}
      <div className={styles.actions}>
        <BubbleContent
          onClick={() => setExternal(false)}
          circle="006"
          title="Return"
          className={styles.bubble}
          circleProps={{ fill: true }}
        />
        <BubbleContent
          to={external ? external.link : ""}
          circle="001"
          title="Visit Site"
          className={styles.bubble}
          circleProps={{ stroke: true, fill: false }}
        />
      </div>
    </Overlay>
  );
};

export { ExternalOverlay };
