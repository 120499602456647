import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStaticQuery, graphql } from "gatsby";
import { MenuItem } from "./AppContextCommon";
import {
  AppContextProps,
  AppContainerProps,
  MenuItemProps,
  StaticQueryProps,
  ExternalItem,
} from "./AppContextTypes";
import { MenuAudioItem } from "../Layout/Menu/MenuAudioItem";
import { AudioItem } from "../../types/data";
import { useWindowDimensions } from "../../utils/hooks";
import * as Analytics from "../../utils/analytics.js";

const AUDIO_KEY = "life-support-audio";
export const HELP_KEY = "life-support-help";

const AppContext = createContext<AppContextProps>({
  menuItems: [MenuItem.Home, MenuItem.About],
  toggleAudioFile: () => false,
  isSavedAudio: () => false,
  textDictionary: {},
  intro: true,
  advanceIntro: () => false,
  pageAnimating: false,
  setPageAnimating: () => false,
  external: false,
  setExternal: () => false,
  isTouch: false,
  windowDimensions: {
    width: 0,
    height: 0,
  },
  setLastStory: () => false,
  navigationReversing: false,
  setNavigationReversing: () => false,
  hasSeenHelp: false,
  setHasSeenHelp: () => false,
});

const getSavedAudioItem = (
  savedAudio: string[],
  audioItems: Array<{ node: AudioItem }>
): MenuItemProps => {
  return {
    label: "Saved Audio",
    icon: "headphones",
    subItems: savedAudio.length
      ? savedAudio.map(audio => ({
          renderChild: (
            <MenuAudioItem
              audioItems={audioItems.map(({ node }) => node)}
              audioId={audio}
            />
          ),
        }))
      : [
          {
            label: "No saved files",
          },
        ],
  };
};

const AppContainer: React.FC<AppContainerProps> = ({ children, location }) => {
  const windowDimensions = useWindowDimensions();

  const hydrate = useRef(false);
  const [pageAnimating, setPageAnimating] = useState(false);
  const [external, setExternal] = useState<ExternalItem | false>(false);
  const [intro, setIntro] = useState(true);
  const [hasSeenHelp, setHasSeenHelp] = useState(false);
  const [savedAudio, setSavedAudio] = useState<string[]>([]);
  const [isTouch, setIsTouch] = useState<boolean>(false);
  const [lastStory, setLastStory] = useState<string>();
  const [navigationReversing, setNavigationReversing] = useState<boolean>(
    false
  );

  const data = useStaticQuery<StaticQueryProps>(graphql`
    query TermQuery {
      terms: allGoogleSheetTermDictionaryRow {
        edges {
          node {
            term
            title
            description
          }
        }
      }
      menuItems: allMenuItem {
        edges {
          node {
            label
            subItems {
              label
              to
            }
          }
        }
      }
      audioItems: allAudioItem {
        edges {
          node {
            snippet
            story
            targetId
            attribution
          }
        }
      }
    }
  `);

  const textDictionary = useMemo<AppContextProps["textDictionary"]>(() => {
    return data.terms.edges.reduce<AppContextProps["textDictionary"]>(
      (prev, curr) => {
        prev[curr.node.term.split(" ").join("-").toLowerCase()] = {
          title: curr.node.title,
          description: curr.node.description,
        };

        return prev;
      },
      {}
    );
  }, [data]);

  const menuItems = useMemo<MenuItemProps[]>(() => {
    return [
      MenuItem.Home,
      ...data.menuItems.edges.map(({ node }) => {
        return node;
      }),
      MenuItem.About,
      getSavedAudioItem(savedAudio, data.audioItems.edges),
    ];
  }, [savedAudio, data]);

  const toggleAudioFile = useCallback((id: string) => {
    setSavedAudio(state => {
      const current = state.slice();
      const idx = current.indexOf(id);

      if (idx >= 0) {
        current.splice(idx, 1);
      } else {
        current.push(id);
      }

      return current;
    });
  }, []);

  const isSavedAudio = useCallback(
    (id: string) => {
      return savedAudio.includes(id);
    },
    [savedAudio]
  );

  useEffect(() => {
    if (hydrate.current) {
      localStorage.setItem(AUDIO_KEY, JSON.stringify(savedAudio));
    }
  }, [savedAudio]);

  useEffect(() => {
    const audio = localStorage.getItem(AUDIO_KEY);

    if (audio) {
      try {
        const parsed = JSON.parse(audio);
        setSavedAudio(parsed);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    const help = localStorage.getItem(HELP_KEY);

    if (help) {
      setHasSeenHelp(true);
    }

    hydrate.current = true;

    setIsTouch(window.matchMedia("(pointer: coarse)").matches);

    // Check if landing page is homepage or not
    if (location.pathname !== "/") {
      setIntro(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasSeenHelp) {
      localStorage.setItem(HELP_KEY, "1");
    }
  }, [hasSeenHelp]);

  const advanceIntro = useCallback(() => {
    setIntro(false);
  }, []);

  const value = {
    menuItems,
    toggleAudioFile,
    isSavedAudio,
    textDictionary,
    intro,
    pageAnimating,
    setPageAnimating,
    advanceIntro,
    external,
    setExternal,
    isTouch,
    windowDimensions,
    lastStory,
    setLastStory,
    navigationReversing,
    setNavigationReversing,
    hasSeenHelp,
    setHasSeenHelp,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = (): AppContextProps => useContext(AppContext);

export { AppContainer, useAppContext };
