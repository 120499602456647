import React from "react";
import { InfoText } from "../InfoText";
// import { RenderProps } from "react-markdown";

interface CodeProps {
  value: string;
  inline: boolean;
}

const CodeRenderer: React.FC<CodeProps> = ({ children, value, inline }) => {
  return <InfoText text={value} />;
};

export { CodeRenderer };
